import { Suspense } from 'react'

import { CircularProgress } from '@mui/material'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { useAppSelector } from '~/redux/hooks'
import ErrorPage from '~/screens/ErrorPage'
import Achievements from '~/screens/lifemap/Achievement'
import BeginStoplight from '~/screens/lifemap/BeginStoplight'
import CreateUser from '~/screens/lifemap/CreateUser'
import Disclaimer from '~/screens/lifemap/Disclaimer'
import EconomicsScreen from '~/screens/lifemap/Economics'
import FamilyMembers from '~/screens/lifemap/FamilyMembers'
import FinalScreen from '~/screens/lifemap/Final'
import LifemapSignature from '~/screens/lifemap/LifemapSignature'
import Location from '~/screens/lifemap/Location'
import Overview from '~/screens/lifemap/Overview'
import PrimaryParticipant from '~/screens/lifemap/PrimaryParticipant'
import Priority from '~/screens/lifemap/Priority'
import SendLifemap from '~/screens/lifemap/SendLifemap'
import SkippedIndicator from '~/screens/lifemap/SkippedIndicator'
import SkippedQuestions from '~/screens/lifemap/SkippedQuestions'
import StoplightQuestionHelp from '~/screens/lifemap/StoplightQuestionHelp'
import StoplightQuestions from '~/screens/lifemap/StoplightQuestions'
import Terms from '~/screens/lifemap/Terms'
import UploadPictures from '~/screens/lifemap/UploadPictures'
import {
  canAccess,
  checkAccess,
  checkAccessToInterventions,
} from '~/utils/role-utils'

import EconomicLibrary from '../screens/EconomicLibrary'
import Stakeholders from '../screens/Stakeholders'
import { ProgressBarProvider } from './ProgressBar'
import MainLayout from './layouts/MainLayout'

const Economics = lazy(
  async () => await import('~/screens/survey-builder/Economics'),
)
const FarewellDynamicLinkSurvey = lazy(
  async () =>
    await import('~/screens/survey-builder/FarewellDynamicLinkSurvey'),
)
const Final = lazy(async () => await import('~/screens/survey-builder/Final'))
const SurveyBuilderInfo = lazy(
  async () => await import('~/screens/survey-builder/Info'),
)
const SurveyBuilderStoplights = lazy(
  async () => await import('~/screens/survey-builder/Stoplights'),
)
const Summary = lazy(
  async () => await import('~/screens/survey-builder/Summary'),
)
const SurveyBuilderPrimaryParticipant = lazy(
  async () =>
    await import('~/screens/survey-builder/SurveyBuilderPrimaryParticipant'),
)
const SurveyBuilderProgressBar = lazy(
  async () => await import('~/screens/survey-builder/SurveyBuilderProgressBar'),
)

const DimensionsView = lazy(async () => await import('../screens/Dimensions'))
const Support = lazy(async () => await import('../screens/Support'))
const SupportForm = lazy(
  async () => await import('../screens/support/SupportForm'),
)
const CollectionView = lazy(
  async () => await import('../screens/support/CollectionView'),
)
const ArticleView = lazy(
  async () => await import('../screens/support/ArticleView'),
)
const Surveys = lazy(async () => await import('../screens/SurveysWithDrafts'))
const SurveyList = lazy(async () => await import('../screens/Surveys'))
const WelcomeDynamicLinkSurvey = lazy(
  async () => await import('../screens/WelcomeDynamicLinkSurvey'),
)
const IndicatorList = lazy(async () => await import('../screens/Indicators'))
const Organizations = lazy(async () => await import('../screens/Organizations'))
const Hubs = lazy(async () => await import('../screens/Hubs'))
const FamilyProfile = lazy(async () => await import('../screens/FamilyProfile'))
const LifemapDetails = lazy(
  async () => await import('../screens/LifemapDetails'),
)
const SelectIndicatorPriority = lazy(
  async () => await import('../screens/priorities/SelectIndicatorPriority'),
)
const SelectIndicatorAchievement = lazy(
  async () =>
    await import('../screens/achievements/SelectIndicatorAchievement'),
)
const Families = lazy(async () => await import('../screens/Families'))
const Dashboard = lazy(async () => await import('../screens/Dashboard'))
const Users = lazy(async () => await import('../screens/Users'))
const UsersInvites = lazy(
  async () => await import('../screens/UserInvitations'),
)
const Reports = lazy(async () => await import('../screens/Reports'))
const Maps = lazy(async () => await import('../screens/Maps'))
const Login = lazy(async () => await import('../screens/Login'))
const ActivateUser = lazy(async () => await import('../screens/ActivateUser'))
const PageNotFound = lazy(async () => await import('../screens/PageNotFound'))
const Solutions = lazy(async () => await import('../screens/Solutions'))
const SolutionsForm = lazy(
  async () => await import('../screens/solutions/SolutionsForm'),
)
const SolutionsView = lazy(
  async () => await import('../screens/solutions/SolutionsView'),
)
const EditPrimaryParticipantForm = lazy(
  async () =>
    await import('../screens/families/edit/EditPrimaryParticipantForm'),
)
const EditFamilyMembersForm = lazy(
  async () => await import('../screens/families/edit/EditFamilyMembersForm'),
)
const EditLocation = lazy(
  async () => await import('../screens/families/edit/EditLocation'),
)
const EditImages = lazy(
  async () => await import('../screens/families/edit/EditImages'),
)
const EditSign = lazy(
  async () => await import('../screens/families/edit/EditSign'),
)
const OrganizationForm = lazy(
  async () => await import('../screens/organizations/OrganizationForm'),
)
const Projects = lazy(async () => await import('../screens/Projects'))
const EditEconomicForm = lazy(
  async () => await import('../screens/families/edit/EditEconomicForm'),
)
const OfflineMaps = lazy(async () => await import('../screens/OfflineMaps'))

const FamilyMyProfile = lazy(
  async () => await import('../screens/families/my-profile/MyProfile'),
)
const UserMyProfile = lazy(async () => await import('../screens/Profile'))
const Interventions = lazy(async () => await import('../screens/Interventions'))
const InterventionForm = lazy(
  async () => await import('../screens/interventions/InterventionForm'),
)
const Preview = lazy(async () => await import('../screens/Preview'))

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export default function MainRouter() {
  const location = useLocation()
  const user = useAppSelector(state => state.user)

  const currentSurvey = useAppSelector(state => state.currentSurvey)

  const currentDraft = useAppSelector(state => state.currentDraft)

  useEffect(() => {
    mixpanel.track('page', {
      pageName: location.pathname,
      currentSurvey,
      currentDraft,
    })
  }, [currentDraft, currentSurvey, location.pathname])

  const canReadOrView = (view: string) =>
    canAccess(user, view, 'read') || canAccess(user, view, 'view')

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            alignItems: 'center',
            backgroundColor: '#f3f4f6',
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      }
    >
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <SentryRoutes>
          <Route path="/users/activate/:token" element={<ActivateUser />} />
          <Route path="/profile" element={<UserMyProfile />} />
          {checkAccess(user, 'surveys') && !user?.dynamicLink && (
            <Route path="/surveys" element={<Surveys />} />
          )}
          {checkAccess(user, 'surveys') && !user?.dynamicLink && (
            <Route path="/login" element={<Surveys />} />
          )}
          {canAccess(user, 'surveysList') && (
            <Route path="/surveysList" element={<SurveyList />} />
          )}
          {user?.dynamicLink && (
            <Route path="/welcome" element={<WelcomeDynamicLinkSurvey />} />
          )}
          {user?.dynamicLink && (
            <Route path="/login" element={<WelcomeDynamicLinkSurvey />} />
          )}
          {canReadOrView('projects') && (
            <Route
              path="/hubs/:hubID/organizations/:orgID/projects"
              element={<Projects />}
            />
          )}
          {canReadOrView('offline-maps') && (
            <Route
              path="/hubs/:hubID/organizations/:orgID/offline-maps"
              element={<OfflineMaps />}
            />
          )}
          {canReadOrView('organizations') && (
            <Route
              path="/hubs/:hubID/organizations"
              element={<Organizations />}
            />
          )}
          {canAccess(user, 'stakeholders') && (
            <Route path="/stakeholders" element={<Stakeholders />} />
          )}
          {canAccess(user, 'organizations', 'write') && (
            <Route path="/organization/create" element={<OrganizationForm />} />
          )}
          {canAccess(user, 'organizations', 'write') && (
            <Route
              path="/organization/:orgId/edit"
              element={<OrganizationForm />}
            />
          )}
          {canAccess(user, 'hubs') && <Route path="/hubs" element={<Hubs />} />}
          {(checkAccess(user, 'surveys') ||
            checkAccess(user, 'my-profile')) && (
            <Route
              path="/lifemap/"
              element={
                <ProgressBarProvider>
                  <Outlet />
                </ProgressBarProvider>
              }
            >
              <Route path="disclaimer" element={<Disclaimer />} />
              <Route path="terms" element={<Terms />} />
              <Route path="privacy" element={<Terms />} />
              <Route path="create-user" element={<CreateUser />} />
              <Route
                path="primary-participant"
                element={<PrimaryParticipant />}
              />
              <Route path="family-members" element={<FamilyMembers />} />
              <Route path="location" element={<Location />} />
              <Route path="economics/:page" element={<EconomicsScreen />} />
              <Route path="begin-stoplight" element={<BeginStoplight />} />
              <Route
                path="help-stoplight"
                element={<StoplightQuestionHelp />}
              />
              <Route path="stoplight/:page" element={<StoplightQuestions />} />
              <Route path="skipped-questions" element={<SkippedQuestions />} />
              <Route
                path="skipped-indicator/:indicator"
                element={<SkippedIndicator />}
              />
              <Route path="achievement/:indicator" element={<Achievements />} />
              <Route path="priority/:indicator" element={<Priority />} />
              <Route path="upload-pictures" element={<UploadPictures />} />
              <Route path="sign" element={<LifemapSignature />} />
              <Route path="overview" element={<Overview />} />
              <Route path="final" element={<FinalScreen />} />
              <Route path="send-lifemap" element={<SendLifemap />} />
              <Route path="farewell" element={<FarewellDynamicLinkSurvey />} />
            </Route>
          )}
          {canAccess(user, 'families') && (
            <Route path="/families" element={<Families />} />
          )}
          {canAccess(user, 'families', 'view') && (
            <Route path="/family/:familyId" element={<FamilyProfile />} />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'families', 'editFamily')) && (
            <Route
              path="/family/:familyId/edit"
              element={<EditPrimaryParticipantForm />}
            />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'families', 'editFamily')) && (
            <Route
              path="/family/:familyId/edit-economic/:topic"
              element={<EditEconomicForm />}
            />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'families', 'editFamily')) && (
            <Route
              path="/family/:familyId/edit-members"
              element={<EditFamilyMembersForm />}
            />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'families', 'editFamily')) && (
            <Route
              path="/family/:familyId/edit-location/:latitude/:longitude"
              element={<EditLocation />}
            />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'families', 'editFamily') ||
            checkAccess(user, 'my-profile')) && (
            <Route
              path="/family/:familyId/edit-images/:snapshotId"
              element={<EditImages />}
            />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'families', 'editFamily') ||
            checkAccess(user, 'my-profile')) && (
            <Route
              path="/family/:familyId/edit-sign/:snapshotId"
              element={<EditSign />}
            />
          )}
          {(canAccess(user, 'families', 'view') ||
            canAccess(user, 'detail', 'view')) && (
            <Route path="/detail/:familyId" element={<LifemapDetails />} />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'priorities', 'write') ||
            canAccess(user, 'families', 'priorities')) && (
            <Route
              path="/priorities/:familyId"
              element={<SelectIndicatorPriority />}
            />
          )}
          {(canAccess(user, 'families', 'write') ||
            canAccess(user, 'achievements', 'write') ||
            canAccess(user, 'families', 'priorities')) && (
            <Route
              path="/achievements/:familyId"
              element={<SelectIndicatorAchievement />}
            />
          )}
          {checkAccess(user, 'my-profile') && (
            <Route path="/my-profile" element={<FamilyMyProfile />} />
          )}
          {canAccess(user, 'dashboard') && (
            <Route path="/dashboard" element={<Dashboard />} />
          )}
          {canAccess(user, 'dashboard') && (
            <Route path="/login" element={<Dashboard />} />
          )}
          {canAccess(user, 'users') && (
            <Route path="/users" element={<Users />} />
          )}
          {canAccess(user, 'users') && (
            <Route path="/users-invite" element={<UsersInvites />} />
          )}
          {canAccess(user, 'reports') && (
            <Route path="/reports" element={<Reports />} />
          )}
          {canAccess(user, 'map') && <Route path="/map" element={<Maps />} />}
          {canAccess(user, 'solutions') && (
            <Route path="/solutions/create" element={<SolutionsForm />} />
          )}
          {canAccess(user, 'solutions') && (
            <Route path="/solution/edit/:id" element={<SolutionsForm />} />
          )}
          {canAccess(user, 'solutions') && (
            <Route path="/solution/:id" element={<SolutionsView />} />
          )}
          {canAccess(user, 'solutions') && (
            <Route path="/solutions" element={<Solutions />} />
          )}
          {!!user && !user.role && <Route path="/login" element={<Login />} />}
          {canAccess(user, 'dashboard') && (
            <Route path="/" element={<Dashboard />} />
          )}
          {checkAccess(user, 'surveys') && !user?.dynamicLink && (
            <Route path="/" element={<Surveys />} />
          )}
          {user?.dynamicLink && (
            <Route path="/" element={<WelcomeDynamicLinkSurvey />} />
          )}
          {(canAccess(user, 'surveysList', 'write') ||
            canAccess(user, 'surveysList')) && (
            <Route
              path="/survey-builder/"
              element={
                <MainLayout>
                  <SurveyBuilderProgressBar />
                  <Outlet />
                </MainLayout>
              }
            >
              <Route path="info" element={<SurveyBuilderInfo />} />

              <Route
                path="primary-participant"
                element={<SurveyBuilderPrimaryParticipant />}
              />
              <Route path="economics" element={<Economics />} />
              <Route path="stoplights" element={<SurveyBuilderStoplights />} />
              <Route path="summary" element={<Summary />} />
              <Route path="final" element={<Final />} />
            </Route>
          )}
          {checkAccessToInterventions(user) && (
            <Route path="/interventions" element={<Interventions />} />
          )}
          {checkAccessToInterventions(user) && (
            <Route
              path="/interventions/create"
              element={<InterventionForm />}
            />
          )}
          {checkAccessToInterventions(user) && (
            <Route
              path="/interventions/edit/:id"
              element={<InterventionForm />}
            />
          )}

          {user && !user?.dynamicLink && (
            <Route path="/support" element={<Support />} />
          )}
          {user && !user?.dynamicLink && (
            <Route path="/article/:id" element={<ArticleView />} />
          )}
          {user && !user?.dynamicLink && (
            <Route path="/articles/create" element={<SupportForm />} />
          )}
          {user && !user?.dynamicLink && (
            <Route path="/articles/edit/:id" element={<SupportForm />} />
          )}
          {user && !user?.dynamicLink && (
            <Route path="/collection/:slug" element={<CollectionView />} />
          )}

          {canAccess(user, 'dimensions') && (
            <Route path="/dimensions" element={<DimensionsView />} />
          )}
          {canAccess(user, 'indicators') && (
            <Route path="/indicators" element={<IndicatorList />} />
          )}
          {canAccess(user, 'economics') && (
            <Route path="/economics" element={<EconomicLibrary />} />
          )}
          {canAccess(user, 'dashboard') && (
            <Route path="/preview" element={<Preview />} />
          )}
          {user?.role && <Route path="*" element={<PageNotFound />} />}
        </SentryRoutes>
      </Sentry.ErrorBoundary>
    </Suspense>
  )
}
