import { combineReducers, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import backupUser from './slices/backupUser'
import currentDraft from './slices/currentDraft'
import currentSurvey from './slices/currentSurvey'
import user from './slices/user'

const rootReducer = combineReducers({
  user,
  backupUser,
  currentDraft,
  currentSurvey,
})

const persistedReducer = persistReducer(
  { key: 'root', version: 1, storage },
  rootReducer,
)

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // @ts-expect-error Idk why this key expects a boolean, the name
        // implies a list of actions to ignore and the runtime behavior is
        // correct
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers.concat(sentryReduxEnhancer)
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

export const persistor = persistStore(store)
