import { Box, FormControl, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { connect } from 'formik'
import { get } from 'lodash'
import moment from 'moment'

import { getErrorLabelForPath, pathHasError } from '~/utils/form-utils'
import { getDateMaskFormat } from '~/utils/format-date'

// Project
import StyledFormLabel from './StyledFormLabel'

/**
 * Types
 * @typedef {import('moment').Moment} momentType
 * @typedef {import('formik').FormikProps<unknown>} formikType
 *
 *
 * @typedef {Object} DatePickerWithFormikProps
 * @property {formikType} formik - Formik object obtained by the connect() function. (HOCs)
 * @property {string} name - Name of the DatePicker, used by Formik to identify it.
 * @property {string} label - Label for the DatePicker show to the user
 * @property {string} textFieldProps - Props for the underlying text field
 * @property {boolean} required - Indicates if the DatePicker is required
 * @property {momentType} minDate - Minimum selectable date
 * @property {momentType} maxDate - Maximum selectable date
 * @property {boolean} disableFuture - Indicates if future dates should be disabled
 */

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
const DatePickerWithFormik = props => {
  /**
   * @type {DatePickerWithFormikProps}
   */
  const {
    formik,
    name,
    label,
    textFieldProps = {},
    required,
    minDate,
    maxDate,
    disableFuture = false,
  } = props

  const { t } = useTranslation()

  // This is a hack. To avoid the problems we have with Formik. Damn you Formik.
  const anchorElRef = useRef(/** @type {(null | HTMLElement)} */ (null))

  const datePickerProps = {
    ...props,
    textFieldProps: props.textFieldProps ?? {},
  }
  const currentValue = get(formik.values, name)
  const error = pathHasError(name, formik.touched, formik.errors)

  const helperText = getErrorLabelForPath(
    name,
    formik.touched,
    formik.errors,
    t,
  )

  // We're  using a ref, so that the inner state isn't updated and the input doesn't
  // lose focus. Note that this is a problem with Formik and not with MUI or this code itself.
  // Once we migrate from Formik to React Hook Forms, we won't have this problem anymore
  const datePickerValue = useRef(/** @type {(null | momentType)} */ (null))

  const onBlur = e => {
    formik.setFieldTouched(name)

    if (props.onChange) props.onChange(datePickerValue.current)
    else formik.setFieldValue(name, datePickerValue.current?.unix())
  }

  return (
    <>
      <DatePicker
        // Again, REALLY bad practice to do this props destructuring, but we don't really
        // know what comes in props, so this is the safest way to refactor
        {...datePickerProps}
        closeOnSelect
        disableFuture={disableFuture}
        minDate={minDate}
        maxDate={maxDate}
        onClose={onBlur}
        onChange={e => {
          datePickerValue.current = e
        }}
        value={currentValue ? moment.unix(currentValue) : null}
        format={getDateMaskFormat()}
        slotProps={{
          popper: {
            anchorEl: anchorElRef.current,
          },
        }}
        slots={{
          textField: muiProps => {
            return (
              <CustomDateTextField
                props={{ ...props, textFieldProps: props.textFieldProps ?? {} }}
                name={name}
                label={label}
                error={error}
                onBlur={onBlur}
                muiProps={muiProps}
                isRequired={required}
                helperText={helperText}
                textFieldProps={textFieldProps}
              />
            )
          },
        }}
      />
      {/* Two words, damn Formik */}
      <Box sx={{ width: 0, height: 0 }} ref={anchorElRef} />
    </>
  )
}

/**
 * @prop {string} name Name used in Formik, to get onChange, Value, etc...
 * @prop {string} label The DatePicker's Label.
 * @prop {string} helperText In this case, the text shown when there's an error.
 * @prop {unknown} error Formik's error for this field.
 * @prop {boolean} isRequired If this field is required or not
 * @prop {unknown} muiProps Don't worry about this prop. It's automatically handled by MUI.
 * @prop {unknown} textFieldProps This is a general prop that we don't know the shape off. Try to avoid using it.
 * @prop {() => void} onBlur Function executed when the input is blured
 * @prop {unknown} props The most dangerous of all props. Be very careful if you refactor this. (We don't know its shape)
 */
function CustomDateTextField({
  name,
  label,
  helperText,
  error,
  isRequired,
  muiProps,
  textFieldProps,
  onBlur,
  props,
}) {
  const {
    i18n: { language },
  } = useTranslation()

  let fullLabel = label + ': ' + getDateMaskFormat().toLowerCase()
  fullLabel = language === 'en' ? fullLabel : fullLabel.replace(/y/g, 'a')

  // NOTE: This is an awful solution. We shouldn't use this kind of "...spread" pattern
  // but until we migrate most components to TS without using props destructuring
  // we'll have to do this.
  const generalTextFieldProps = {
    ...props,
    ...textFieldProps,
    ...muiProps,
  }

  return (
    <FormControl fullWidth>
      <StyledFormLabel component="legend" required={isRequired} error={error}>
        {fullLabel}
      </StyledFormLabel>
      <TextField
        {...generalTextFieldProps}
        fullWidth
        error={error}
        name={name}
        variant="filled"
        onBlur={onBlur}
        helperText={helperText}
      />
    </FormControl>
  )
}

export default connect(DatePickerWithFormik)
